import { createEnv } from "@t3-oss/env-nextjs";
import { vercel } from "@t3-oss/env-nextjs/presets";
import { z } from "zod";

export const env = createEnv({
  extends: [vercel()],
  /**
   * Specify your server-side environment variables schema here. This way you can ensure the app
   * isn't built with invalid env vars.
   */
  server: {
    // non-sensitive
    CI: z
      .string()
      .default("0")
      .transform((val) => val === "1" || val.toLowerCase() === "true"),
    NODE_ENV: z
      .enum(["development", "test", "production"])
      .default("development"),
    PLAYGROUND_PROFILE_ID: z.string().default("cm70u1rvr0003vfszxftw09is"),

    // sensitive
    AGENT_AUTH_TOKEN_SECRET: z.string(),
    ANTHROPIC_API_KEY: z.string(),
    CARTESIA_API_KEY: z.string(),
    DATABASE_URL: z.string().url(),
    GREENHOUSE_WEBHOOK_SECRET: z.string(),
    GROQ_API_KEY: z.string(),
    HAMMING_API_KEY: z.string(),
    INNGEST_SIGNING_KEY: z.string(),
    INNGEST_EVENT_KEY: z.string(),
    LIVEKIT_API_KEY: z.string(),
    LIVEKIT_API_KEY_INBOUND: z.string(),
    LIVEKIT_API_SECRET: z.string(),
    LIVEKIT_API_SECRET_INBOUND: z.string(),
    LIVEKIT_URL: z.string(),
    LIVEKIT_URL_INBOUND: z.string(),
    LIVEKIT_URL_INBOUND_STAGING: z.string(),
    LIVEKIT_API_KEY_INBOUND_STAGING: z.string(),
    LIVEKIT_API_SECRET_INBOUND_STAGING: z.string(),
    OPENAI_API_KEY: z.string(),
    RESEND_API_KEY: z.string(),
    SLACK_BOT_TOKEN: z.string(),
    TELNYX_API_KEY: z.string(),
    TELNYX_OUTBOUND_TRUNK_PASSWORD: z.string(),
    TELNYX_OUTBOUND_TRUNK_USERNAME: z.string(),
    TELNYX_PUBLIC_KEY: z.string(),
    TWILIO_ACCOUNT_SID: z.string(),
    TWILIO_AUTH_TOKEN: z.string(),
    TWILIO_OUTBOUND_TRUNK_PASSWORD: z.string(),
    TWILIO_OUTBOUND_TRUNK_USERNAME: z.string(),
  },

  /**
   * Specify your client-side environment variables schema here. This way you can ensure the app
   * isn't built with invalid env vars. To expose them to the client, prefix them with
   * `NEXT_PUBLIC_`.
   */
  client: {
    NEXT_PUBLIC_HIGHLIGHT_PROJECT_ID: z.string(),
    NEXT_PUBLIC_VERCEL_ENV: z.string(),
  },

  /**
   * You can't destruct `process.env` as a regular object in the Next.js edge runtimes (e.g.
   * middlewares) or client-side so we need to destruct manually.
   */
  runtimeEnv: {
    CI: process.env.CI,
    NODE_ENV: process.env.NODE_ENV,
    PLAYGROUND_PROFILE_ID: process.env.PLAYGROUND_PROFILE_ID,

    NEXT_PUBLIC_VERCEL_ENV: process.env.VERCEL_ENV ?? "development",
    NEXT_PUBLIC_HIGHLIGHT_PROJECT_ID:
      process.env.NEXT_PUBLIC_HIGHLIGHT_PROJECT_ID,

    AGENT_AUTH_TOKEN_SECRET: process.env.AGENT_AUTH_TOKEN_SECRET,
    ANTHROPIC_API_KEY: process.env.ANTHROPIC_API_KEY,
    CARTESIA_API_KEY: process.env.CARTESIA_API_KEY,
    DATABASE_URL: process.env.DATABASE_URL,
    GREENHOUSE_WEBHOOK_SECRET: process.env.GREENHOUSE_WEBHOOK_SECRET,
    GROQ_API_KEY: process.env.GROQ_API_KEY,
    HAMMING_API_KEY: process.env.HAMMING_API_KEY,
    INNGEST_SIGNING_KEY: process.env.INNGEST_SIGNING_KEY,
    INNGEST_EVENT_KEY: process.env.INNGEST_EVENT_KEY,
    LIVEKIT_API_KEY: process.env.LIVEKIT_API_KEY,
    LIVEKIT_API_KEY_INBOUND: process.env.LIVEKIT_API_KEY_INBOUND,
    LIVEKIT_API_SECRET: process.env.LIVEKIT_API_SECRET,
    LIVEKIT_API_SECRET_INBOUND: process.env.LIVEKIT_API_SECRET_INBOUND,
    LIVEKIT_URL_INBOUND_STAGING: process.env.LIVEKIT_URL_INBOUND_STAGING,
    LIVEKIT_API_KEY_INBOUND_STAGING:
      process.env.LIVEKIT_API_KEY_INBOUND_STAGING,
    LIVEKIT_API_SECRET_INBOUND_STAGING:
      process.env.LIVEKIT_API_SECRET_INBOUND_STAGING,
    LIVEKIT_URL: process.env.LIVEKIT_URL,
    LIVEKIT_URL_INBOUND: process.env.LIVEKIT_URL_INBOUND,
    OPENAI_API_KEY: process.env.OPENAI_API_KEY,
    RESEND_API_KEY: process.env.RESEND_API_KEY,
    SLACK_BOT_TOKEN: process.env.SLACK_BOT_TOKEN,
    TELNYX_API_KEY: process.env.TELNYX_API_KEY,
    TELNYX_OUTBOUND_TRUNK_PASSWORD: process.env.TELNYX_OUTBOUND_TRUNK_PASSWORD,
    TELNYX_OUTBOUND_TRUNK_USERNAME: process.env.TELNYX_OUTBOUND_TRUNK_USERNAME,
    TELNYX_PUBLIC_KEY: process.env.TELNYX_PUBLIC_KEY,
    TWILIO_ACCOUNT_SID: process.env.TWILIO_ACCOUNT_SID,
    TWILIO_AUTH_TOKEN: process.env.TWILIO_AUTH_TOKEN,
    TWILIO_OUTBOUND_TRUNK_PASSWORD: process.env.TWILIO_OUTBOUND_TRUNK_PASSWORD,
    TWILIO_OUTBOUND_TRUNK_USERNAME: process.env.TWILIO_OUTBOUND_TRUNK_USERNAME,
  },
  /**
   * Run `build` or `dev` with `SKIP_ENV_VALIDATION` to skip env validation. This is especially
   * useful for Docker builds.
   */
  skipValidation: Boolean(process.env.SKIP_ENV_VALIDATION),
  /**
   * Makes it so that empty strings are treated as undefined. `SOME_VAR: z.string()` and
   * `SOME_VAR=''` will throw an error.
   */
  emptyStringAsUndefined: true,
});
